import { AlertController, ToastController } from '@ionic/angular';

export class IxToast {

}

const toastController = new ToastController()
const alertController = new AlertController()

export const ixToast = async (message) => {
    // const toast = toastController.create({
    //     message: message,
    //     duration: 3000
    // });
    // (await toast).present();
    const alert = await alertController.create({
        cssClass: 'alert-custom-class',
        header: 'Error',
        // subHeader: 'Subtitle',
        message: message,
        buttons: ['Got it'],
    });
    await alert.present();
}