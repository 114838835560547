import { AuthorizationState } from '../store/states/authorization.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { API } from 'aws-amplify';
import { UpdateProgressCount } from '../store/actions/progress.action';
import { Injectable } from '@angular/core';
import { ixToast } from './ix-toast';

const API_NAME = 'influxapi';

const HTTP_ERROR_STATUS_CODE = 400
const HTTP_ERROR_OTHER = 100
const HTTP_ERROR_PRIVILEGE = 101
const HTTP_ERROR_OWENERSHIP = 102
const HTTP_ERROR_FAILED = 103
const HTTP_ERROR_FAILED_STORM_API = 104

class helper {
    store: Store
    constructor(private s: Store) {
        this.store = s
    }
}

@Injectable({
    providedIn: 'root'
})
export class IxHttp {

    private static x: IxHttp;
    @Select(AuthorizationState.token) token: Observable<any>;
    header = {};
    count = 0

    api_get = API.get

    public constructor(private store: Store) {
        // this.store = new Store()
        this.token.subscribe(token => {
            if (!token) return
            this.header = {
                headers: {
                    // "Policy": token
                }
            }
        });
    }

    // static instance() {
    //     if (!IxHttp.x) {
    //         IxHttp.x = new IxHttp();
    //         IxHttp.x.init()
    //     }
    //     return IxHttp.x;
    // }

    init = () => { }

    get = async (url, params = {}, progress = 1) => {
        return await this.restful('get', url, params, progress)
    }

    show = async (url, params = {}, progress = 1) => {
        return await this.restful('show', url, params, progress)
    }

    new = async (url, params = {}, progress = 1) => {
        return await this.restful('new', url, params, progress)
    }

    post = async (url, params = {}, progress = 1) => {
        return await this.restful('post', url, params, progress)
    }

    edit = async (url, params = {}, progress = 1) => {
        return await this.restful('edit', url, params, progress)
    }

    put = async (url, params = {}, progress = 1) => {
        return await this.restful('put', url, params, progress)
    }

    delete = async (url, params = {}, progress = 1) => {
        return await this.restful('del', url, params, progress)
    }

    restful = async (action, url, params = {}, progress) => {
        const data = { ...this.header, ...params }
        // console.log(data)
        try {
            if (progress) {
                this.count++
                this.store.dispatch(new UpdateProgressCount(this.count));
            }
            const res = await API[action](API_NAME, url, data)
                .then(response => response)
                .catch(error => this.errorHanlding(error));
            // console.log(res)
            if (progress) {
                this.count--
                this.store.dispatch(new UpdateProgressCount(this.count));
            }
            return res
        } catch (e) {
            console.log(555)
            if (progress) {
                this.count--
                this.store.dispatch(new UpdateProgressCount(this.count));
            }
        }
    }

    errorHanlding = (error) => {
        console.log("getting here");
        console.log(error.response);
        if (!error || !error.response || !error.response.status) {
            return
        }
        let message = 'Unknown error happened, please contact your administrator.'
        switch (error.response.status) {
            case 403:
                message = error.response.data ?? 'User is unauthorised'
                break;
            case 502:
            case 504:
                message = 'Server error, please contact your administrator.'
                break;
            case HTTP_ERROR_STATUS_CODE:
                const data = error.response.data
                switch (data.code) {
                    case HTTP_ERROR_PRIVILEGE:
                        break;
                }
                if (data.message) message = data.message
                break;
        }
        ixToast(message)
        
    }
}

