/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "influxapi",
            "endpoint": "https://li21khv97d.execute-api.ap-southeast-2.amazonaws.com/jackdev",
            "region": "ap-southeast-2"
        },
        {
            "name": "integration",
            "endpoint": "https://wo4i4mzodk.execute-api.ap-southeast-2.amazonaws.com/jackdev",
            "region": "ap-southeast-2"
        },
        {
            "name": "webhook",
            "endpoint": "https://42dzvy3gjh.execute-api.ap-southeast-2.amazonaws.com/jackdev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:3b0a7014-ce61-4722-a353-6b4fdebc8520",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_begnmJWY6",
    "aws_user_pools_web_client_id": "3gk97s47rocs19clquba0if2s1",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "commissioningCompletedMessage-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "controllableLoadConsumption-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discoreco-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discorecoTasks-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "gmexport-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "group-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "groupusers-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrol-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrolhistory-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterDataExportHistory-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterEvents-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterStatusRefreshHistory-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterstatus-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "onsitemetercomm-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "org-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "policy-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "provisioningCompletedMessage-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "service-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tasks-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "touLatestReportMessage-jackdev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "user-jackdev",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
